<script setup>
/* eslint-disable */
import * as THREE from 'three';

const threeContainer = ref(null);

onMounted(() => {
  const scene = new THREE.Scene();

  // Criação da câmera (perspectiva) com ajuste
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  camera.position.z = 1;

  // Criação do renderizador
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  threeContainer.value.appendChild(renderer.domElement); // Anexa o renderizador ao DOM

  // Defina os códigos dos shaders como strings
  const vertexShaderCode = `
      void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
  `;

  const fragmentShaderCode = `
      uniform vec2 u_resolution;
      uniform float u_time;
      uniform vec3 u_color;
      uniform vec4 u_background;
      uniform float u_speed;
      uniform float u_detail;

      mat2 m(float a) {
          float c = cos(a), s = sin(a);
          return mat2(c,-s,s,c);
      }

      float rgb2luma(in vec3 color) {
          return dot(color, vec3(0.299, 0.587, 0.114));
      }

      float map(vec3 p) {
          float t = u_time * u_speed;
          p.xz *= m(t * 0.4);
          p.xy *= m(t * 0.1);
          vec3 q = p * 2.0 + t;
          return length(p + vec3(sin((t * u_speed) * 0.1))) * log(length(p) + 0.9) + cos(q.x + sin(q.z + cos(q.y))) * 0.5 - 1.0;
      }

      void main() {
          // Ajuste para centralizar a renderização
          vec2 a = (gl_FragCoord.xy - 0.5 * u_resolution.xy) / u_resolution.y;
          
          vec3 cl = vec3(0.0);
          float d = 2.5;

          for (float i = 0.; i <= (1. + 20. * u_detail); i++) {
              vec3 p = vec3(0, 0, 4.0) + normalize(vec3(a, -1.0)) * d;
              float rz = map(p);
              float f = clamp((rz - map(p + 0.1)) * 0.5, -0.1, 1.0);
              vec3 l = vec3(0.1, 0.3, 0.4) + vec3(5.0, 2.5, 3.0) * f;
              cl = cl * l + smoothstep(2.5, 0.0, rz) * 0.6 * l;
              d += min(rz, 1.0);
          }

          vec4 color = vec4(min(u_color, cl), 1.0);
          color.r = max(u_background.r, color.r);
          color.g = max(u_background.g, color.g);
          color.b = max(u_background.b, color.b);

          gl_FragColor = color;
      }
  `;

  // Uniforms para passar valores ao shader
  const uniforms = {
    u_resolution: { type: 'v2', value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
    u_time: { type: 'f', value: 1.0 },
    u_color: { type: 'v3', value: new THREE.Color(0.3137254901960784, 0, 1) }, // roxo
    u_background: { type: 'v4', value: new THREE.Vector4(0, 0, 0, 1) }, // fundo preto
    u_speed: { type: 'f', value: 0.05 },
    u_detail: { type: 'f', value: 0.25 }
  };

  // Função para ajustar o plano geométrico ao tamanho da tela
  function createFullscreenPlane() {
    const aspect = window.innerWidth / window.innerHeight;
    const geometry = new THREE.PlaneGeometry(2 * aspect, 2); // Ajuste o plano para a proporção da tela
    return new THREE.Mesh(geometry, material);
  }

  // Crie o material do shader
  const material = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: vertexShaderCode,
    fragmentShader: fragmentShaderCode
  });

  // Adicionar o plano inicial
  let mesh = createFullscreenPlane();
  scene.add(mesh);

  // Função de animação
  function animate() {
    requestAnimationFrame(animate);
    uniforms.u_time.value += 0.05; // atualiza o tempo
    renderer.render(scene, camera);
  }
  animate();

  // Atualiza o tamanho do canvas e dos uniforms se a janela mudar de tamanho
  window.addEventListener('resize', () => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    
    // Atualiza o uniforme de resolução
    uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
    
    // Remove o plano antigo e adiciona um novo com o tamanho ajustado
    scene.remove(mesh);
    mesh = createFullscreenPlane();
    scene.add(mesh);
  });
})

</script>

<template>
  <div ref="threeContainer" />
</template>
